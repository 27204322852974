<template>
    <main-container :icon="$route.meta.icon" :title="$route.meta.title">
    <emc-loader-progress :show="loadingProgress" />
    
    <EmcTableItems 
        :headers="headers" 
        v-bind:params.sync="params"
        v-bind:collections.sync="collections"
        v-bind:meta.sync="meta"
        v-bind:itemDelete.sync="itemDelete" 
        v-bind:itemEdit.sync="model"
        :loading="loading"
        :show-delete="false"
    >  
        
        <template v-slot:item.user="{ item }">
            <span v-if="item.user">{{ item.user.name }}</span>
            <span v-else-if="getUser(item)">{{ getUser(item).name }}</span>
        </template>

    </EmcTableItems>
    
    <!-- Modal para editar -->
        <emc-modal-form :title="titleForm" :icon="$route.meta.icon" v-bind:show.sync="showForm">
            <template v-slot:form>
                <v-card v-if="model" class="mx-auto">
                    <v-app-bar color="white">
                        <EmcBaseAvatar 
                            v-if="getUser()"
                            :size="36"
                            :src="getUser().url_avatar ? getUser().url_avatar : ''"
                            :text="getUser().initials ? getUser().initials : 'SU'"
                            class="mr-2"
                        />
                        <v-toolbar-title> 
                            <span v-if="getUser()">{{ getUser().name }} </span>
                            <span v-else>Sem registro de usuário</span>

                            <small v-if="model.ip"><br/>IP: {{ model.ip }}</small>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-title> [{{ model.method }}] - {{ model.path }}</v-toolbar-title>
                        
                    </v-app-bar>
                    <v-container>
                        <v-row dense>
                            <v-col cols="12">
                                <vue-json-pretty v-if="model.properties" :path="'res'" :data="model.properties.params"> </vue-json-pretty>
                            </v-col>
                        </v-row>
                        <small>
                            <v-icon small>mdi-clock-outline</v-icon> 
                            <span>data {{ model.created_at | dateTime }}</span>
                        </small>
                    </v-container>
                </v-card>
            </template>
        </emc-modal-form>

    </main-container>
</template>

<script> 
import { mapActions } from 'vuex'
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
export default{
  data: () => ({
    collections: [],
    params: {},
    loading: false,
    meta: {},
    itemDelete: {},
    showImage: false,
    selectedAmbience: null,
    model: {
        properties: {
            user: []
        }
    },
    show: false,
    showForm: false,
    loadingProgress: false,
    textDelete: '',
    showImport: false,
    deleting: false,
    titleForm: "Visualizar log",
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Usuário', value: 'user', sortable: false },
        { text: 'Método', value: 'method', sortable: false },
        { text: 'Caminho', value: 'path', sortable: false },
        { text: 'Acesso em', value: 'created_at', sortable: false, type: 'dateTime' },
        { text: 'Ações', value: 'actions', sortable: false }
    ]
    }),
    components: {
        VueJsonPretty,
    },
    watch: {
        params(params_new, params_old){
            if(params_new != params_old) {
                this.getData()
            }
        },
      model(item_new, item_old){
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {
                    properties: {
                        user: []
                    }
                };
          }
      },
    },
    methods: {
      ...mapActions('logAccessApi', ['ActionIndexLogAccessApi']),
      
      getUser (item = null) {
            
            let model = item ? item : this.model;
            
            if(model && model.properties && model.properties.user){
                return model.properties.user;
            }else{
                return null;
            }
        },
      
      getData() {
         
        this.params['column_order'] = 'created_at';
        this.params['direction'] = 'desc';
        this.params['with'] = 'user';
        const params = this.params;
        this.loadingProgress = true;
        this.showForm = false;
        //Object.assign(params, { with: 'type,campus,floor,block,resources,images' });
        
        this.ActionIndexLogAccessApi(params)
            .then((res) => {
                this.collections = res.data
                this.meta = res.meta
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
            })
    },
  },

}

</script>

